import React from 'react';

export const Trash = () => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9062 3.0625H12.2695L11.0742 1.09375C10.7578 0.566406 10.2305 0.25 9.63281 0.25H6.08203C5.48438 0.25 4.95703 0.566406 4.64062 1.09375L3.44531 3.0625H0.808594C0.351562 3.0625 0 3.44922 0 3.90625C0 4.32812 0.351562 4.75 0.808594 4.75H1.125L1.86328 16.668C1.89844 17.582 2.63672 18.25 3.55078 18.25H12.1641C13.0781 18.25 13.8164 17.582 13.8516 16.668L14.625 4.75H14.9062C15.3633 4.75 15.75 4.39844 15.75 3.90625C15.75 3.44922 15.3633 3.0625 14.9062 3.0625ZM6.08203 1.9375H9.63281L10.3008 3.0625H5.41406L6.08203 1.9375ZM12.1641 16.5625H3.55078L2.8125 4.75H12.9023L12.1641 16.5625Z"
        fill="#9097AF"
      />
    </svg>
  );
};
