import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './App.css';
import IntentsPage from './pages/IntentsPage';
import Notification from './components/Notification/Notification';
import {
  getError,
  getStatus,
  getSuccess,
  setError,
  setSuccess,
} from './slices/intentsSlice';

const App = () => {
  const status = useSelector(getStatus);
  const success = useSelector(getSuccess);
  const error = useSelector(getError);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error || success) {
      setTimeout(() => {
        dispatch(setSuccess(''));
        dispatch(setError(''));
      }, 3000);
    }
  }, [error, success]);

  return (
    <div>
      {status === 'failed' && error && (
        <Notification type="error" text={error} />
      )}

      {status === 'succeeded' && success && (
        <Notification type="success" text={success} />
      )}

      <IntentsPage />
    </div>
  );
};

export default App;
