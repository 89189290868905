import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiStopwords, Stopword } from '../api/api-stopwords';

type Status = 'loading' | 'succeeded' | 'failed';

interface StopwordsState {
  stopwords: Stopword[];
  status?: Status;
  error?: string;
  success?: string;
}

const initialState = {
  stopwords: [],
  status: 'loading',
  error: '',
  success: '',
};

export const fetchStopwords = createAsyncThunk(
  'stopwords/fetchStopwords',
  async () => {
    const response = await apiStopwords.getStopwords();
    console.log('response ', response);
    return response.data;
  },
);

export const createStopword = createAsyncThunk(
  'stopwords/createStopword',
  async (newStopword: string) => {
    await apiStopwords.postStopword(newStopword);
    const response = await apiStopwords.getStopwords();
    return response.data;
  },
);

export const editStopword = createAsyncThunk(
  'stopwords/editStopword',
  async (updatedStopword: Stopword) => {
    await apiStopwords.editStopword(updatedStopword);
    const response = await apiStopwords.getStopwords();
    return response.data;
  },
);

export const deleteStopword = createAsyncThunk(
  'stopwords/deleteStopword',
  async (id: string) => {
    await apiStopwords.deleteStopword(id);
    const response = await apiStopwords.getStopwords();
    return response.data;
  },
);

export const selectAllStopwords = (state: { stopwords: StopwordsState }) => {
  return state.stopwords.stopwords;
};

export const getStatus = (state: { stopwords: StopwordsState }) =>
  state.stopwords.status;

export const getError = (state: { stopwords: StopwordsState }) =>
  state.stopwords.error;

export const getSuccess = (state: { stopwords: StopwordsState }) =>
  state.stopwords.success;

export const stopwordsSlice = createSlice({
  name: 'stopwords',
  initialState,

  reducers: {
    setSuccess(state, action) {
      state.success = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStopwords.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchStopwords.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stopwords = action.payload;
      })
      .addCase(fetchStopwords.rejected, (state) => {
        state.status = 'failed';
        state.error = 'fetching failed';
      })
      .addCase(createStopword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createStopword.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stopwords = action.payload;
        state.success = 'Stopword successfully added!';
      })
      .addCase(createStopword.rejected, (state) => {
        state.error =
          'An error occured while creating an stopword. Please try again.';
        state.status = 'failed';
      })
      .addCase(editStopword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editStopword.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stopwords = action.payload;
        state.success = 'Intent successfully updated!';
      })
      .addCase(editStopword.rejected, (state) => {
        state.error =
          'An error occured while updating an intent. Please try again.';
        state.status = 'failed';
      })
      .addCase(deleteStopword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteStopword.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.stopwords = action.payload;
        state.success = 'Intent successfully deleted!';
      })
      .addCase(deleteStopword.rejected, (state) => {
        state.error =
          'An error occured while creating an intent. Please try again.';
        state.status = 'failed';
      });
  },
});

export const { setSuccess, setError } = stopwordsSlice.actions;

export default stopwordsSlice.reducer;
