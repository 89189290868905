import React from 'react';

const CloseIcon: React.FC<{
  fill?: string;
  opacity?: string;
}> = ({ fill = '#B9C0D7', opacity = '1.0' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity}
        d="M16.8438 15.293C17.3008 15.8008 17.3008 16.5625 16.8438 17.0195C16.3359 17.5273 15.5742 17.5273 15.1172 17.0195L9.125 10.9766L3.08203 17.0195C2.57422 17.5273 1.8125 17.5273 1.35547 17.0195C0.847656 16.5625 0.847656 15.8008 1.35547 15.293L7.39844 9.25L1.35547 3.20703C0.847656 2.69922 0.847656 1.9375 1.35547 1.48047C1.8125 0.972656 2.57422 0.972656 3.03125 1.48047L9.125 7.57422L15.168 1.53125C15.625 1.02344 16.3867 1.02344 16.8438 1.53125C17.3516 1.98828 17.3516 2.75 16.8438 3.25781L10.8008 9.25L16.8438 15.293Z"
        fill={fill}
      />
    </svg>
  );
};

export default CloseIcon;
