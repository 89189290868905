import { instance } from './api';

export interface IntentRequest {
  name: string;
  utterances: string[];
  answers: string[];
  intentType?: 'common' | 'shop';
}

export interface Intent extends IntentRequest {
  id: string;
}

export const apiIntents = {
  getIntents: (
    pagination?: { currentPage?: string; numberOfRows?: string },
    searchBy?: string,
    intentType?: 'common' | 'shop',
  ) => {
    return instance.get('/intents', {
      params: {
        currentPage: pagination?.currentPage,
        numberOfRows: pagination?.numberOfRows,
        searchBy,
        filterByType: intentType,
      },
    });
  },
  getIntent: (intentId: string) => {
    return instance.get(`/intents/${intentId}`);
  },
  deleteIntent: (intentId: string) => {
    return instance.delete(`/intents/${intentId}`);
  },
  editIntent: (intent: Intent) => {
    return instance.put(`/intents/${intent.id}`, {
      ...intent,
      intentType: intent.intentType ?? 'common',
    });
  },
  postIntents: (intents: IntentRequest[]) => {
    return instance.post('/intents', { intents });
  },
};
