import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiIntents, Intent, IntentRequest } from '../api/api-intents';

type Status = 'loading' | 'succeeded' | 'failed';

interface IntentsState {
  intents: Intent[];
  totalCount: number;
  status?: Status;
  error?: string;
  success?: string;
  newIntentIsValid: boolean;
}

const initialState = {
  intents: [],
  totalCount: 0,
  status: 'loading',
  error: '',
  success: '',
  newIntentIsValid: true,
};

export const fetchIntents = createAsyncThunk(
  'intents/fetchIntents',
  async ({
    pagination,
    searchBy,
    intentType,
  }: {
    pagination?: { currentPage: string; numberOfRows: string };
    searchBy?: string;
    intentType?: 'common' | 'shop';
  }) => {
    const response = await apiIntents.getIntents(
      pagination,
      searchBy,
      intentType,
    );
    return response.data;
  },
);

export const createIntent = createAsyncThunk(
  'intents/createIntent',
  async (newIntent: IntentRequest) => {
    await apiIntents.postIntents([newIntent]);
    const response = await apiIntents.getIntents();
    return response.data;
  },
);

export const editIntent = createAsyncThunk(
  'intents/editIntent',
  async (updatedIntent: Intent) => {
    await apiIntents.editIntent(updatedIntent);
    const response = await apiIntents.getIntents();
    return response.data;
  },
);

export const deleteIntent = createAsyncThunk(
  'intents/deleteIntent',
  async (id: string) => {
    await apiIntents.deleteIntent(id);
    const response = await apiIntents.getIntents();
    return response.data;
  },
);

export const validateIntent = createAsyncThunk(
  'intents/validate',
  async (value: string) => {
    const response = await apiIntents.getIntents(undefined, value);
    if (response.data.intents.length > 0) {
      throw Error('Data is not valid');
    }
  },
);

export const selectAllIntents = (state: { intents: IntentsState }) =>
  state.intents.intents;

export const getStatus = (state: { intents: IntentsState }) =>
  state.intents.status;

export const getError = (state: { intents: IntentsState }) =>
  state.intents.error;

export const getSuccess = (state: { intents: IntentsState }) =>
  state.intents.success;

export const intentIsValid = (state: { intents: IntentsState }) =>
  state.intents.newIntentIsValid;

export const getTotalCount = (state: { intents: IntentsState }) =>
  state.intents.totalCount;

export const intentsSlice = createSlice({
  name: 'intents',
  initialState,

  reducers: {
    setSuccess(state, action) {
      state.success = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIntents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIntents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.intents = action.payload.intents;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchIntents.rejected, (state) => {
        state.status = 'failed';
        state.error = 'fetching failed';
      })
      .addCase(createIntent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createIntent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.intents = action.payload.intents;
        state.totalCount = action.payload.totalCount;
        state.success = 'Intent successfully added!';
      })
      .addCase(createIntent.rejected, (state) => {
        state.error =
          'An error occured while creating an intent. Please try again.';
        state.status = 'failed';
      })
      .addCase(editIntent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editIntent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.intents = action.payload.intents;
        state.totalCount = action.payload.totalCount;
        state.success = 'Intent successfully updated!';
      })
      .addCase(editIntent.rejected, (state) => {
        state.error =
          'An error occured while updating an intent. Please try again.';
        state.status = 'failed';
      })
      .addCase(deleteIntent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteIntent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.intents = action.payload.intents;
        state.totalCount = action.payload.totalCount;
        state.success = 'Intent successfully deleted!';
      })
      .addCase(deleteIntent.rejected, (state) => {
        state.error =
          'An error occured while creating an intent. Please try again.';
        state.status = 'failed';
      })
      .addCase(validateIntent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(validateIntent.fulfilled, (state) => {
        state.status = 'succeeded';
        state.newIntentIsValid = true;
      })
      .addCase(validateIntent.rejected, (state) => {
        state.status = 'failed';
        state.newIntentIsValid = false;
      });
  },
});

export const { setSuccess, setError } = intentsSlice.actions;

export default intentsSlice.reducer;
