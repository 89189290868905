import React from 'react';
import classNames from 'classnames';

import styles from './Pagination.module.css';
import { Arrow } from '../Icons/Arrow';
import { Ellipse } from '../Icons/Ellipse';

interface PaginationProps {
  currentPage: number;
  totalNumberOfPages: number;
  onPageChange: (newPage: number) => void;
}

const PaginationBlock = ({
  value,
  isActive,
  onClickAction,
}: {
  value: number;
  isActive: boolean;
  onClickAction: (newPage: number) => void;
}) => {
  return (
    <div
      className={classNames({
        [styles.paginationBlock]: true,
        [styles.paginationBlock_active]: isActive,
      })}
      onClick={() => (onClickAction ? onClickAction(value) : undefined)}
    >
      {value}
    </div>
  );
};

const Filler = () => (
  <div className={styles.paginationFiller}>
    <Ellipse />
  </div>
);

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalNumberOfPages,
  onPageChange,
}) => {
  const left = [];
  let leftFiller;
  const middle = [];
  let rightFiller;
  const right = [];

  // [1,2,3,4,5]
  if (totalNumberOfPages <= 5) {
    for (let i = 1; i <= totalNumberOfPages; i++) {
      left.push(
        <PaginationBlock
          key={i}
          value={i}
          onClickAction={(newPage: number) => onPageChange(newPage)}
          isActive={i === currentPage}
        />,
      );
    }

    // [1,2,3,4...X]
  } else if (currentPage < 4) {
    for (let i = 1; i <= 4; i++) {
      left.push(
        <PaginationBlock
          key={i}
          value={i}
          onClickAction={(newPage: number) => onPageChange(newPage)}
          isActive={i === currentPage}
        />,
      );
    }
    rightFiller = <Filler />;
    right.push(
      <PaginationBlock
        value={totalNumberOfPages}
        onClickAction={(newPage: number) => onPageChange(newPage)}
        isActive={false}
      />,
    );
    // [1...5,6,7,8]
  } else if (currentPage > totalNumberOfPages - 3) {
    for (let i = totalNumberOfPages - 3; i <= totalNumberOfPages; i++) {
      right.push(
        <PaginationBlock
          key={i}
          value={i}
          onClickAction={(newPage: number) => onPageChange(newPage)}
          isActive={i === currentPage}
        />,
      );
    }
    leftFiller = <Filler />;
    left.push(
      <PaginationBlock
        value={1}
        onClickAction={(newPage: number) => onPageChange(newPage)}
        isActive={false}
      />,
    );
    // [1..4,5,6...10]
  } else {
    left.push(
      <PaginationBlock
        value={1}
        onClickAction={(newPage: number) => onPageChange(newPage)}
        isActive={false}
      />,
    );
    leftFiller = <Filler />;
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      middle.push(
        <PaginationBlock
          key={i}
          value={i}
          onClickAction={(newPage: number) => onPageChange(newPage)}
          isActive={i === currentPage}
        />,
      );
    }
    rightFiller = <Filler />;
    right.push(
      <PaginationBlock
        value={totalNumberOfPages}
        onClickAction={(newPage: number) => onPageChange(newPage)}
        isActive={false}
      />,
    );
  }

  return (
    <div className={styles.paginationWrapper}>
      <div
        className={styles.paginationArrow}
        onClick={() =>
          currentPage !== 1 ? onPageChange(currentPage - 1) : null
        }
      >
        <Arrow direction="left" isActive={currentPage !== 1} />
      </div>
      {left}
      {leftFiller}
      {middle}
      {rightFiller}
      {right}
      <div
        className={styles.paginationArrow}
        onClick={() =>
          currentPage !== totalNumberOfPages
            ? onPageChange(currentPage + 1)
            : null
        }
      >
        <Arrow
          direction="right"
          isActive={currentPage !== totalNumberOfPages}
        />
      </div>
    </div>
  );
};

export default Pagination;
