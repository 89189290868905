import React, { useEffect, useRef } from 'react';

import styles from './Modal.module.css';
import Button from '../Button/Button';
import IconButton from '../IconButton/IconButton';
import CloseIcon from '../Icons/Close';

interface ModalProps {
  modalTitle: string;
  onClickConfirm: () => void;
  onClickClose: () => void;
  confirmButtonTitle: string;
  confirmButtonDisabled?: boolean;
  confirmButtonType?: 'primary' | 'secondary' | 'error';
  children?: any;
}

const Modal: React.FC<ModalProps> = ({
  modalTitle,
  onClickConfirm,
  onClickClose,
  confirmButtonTitle,
  confirmButtonDisabled = false,
  confirmButtonType = 'primary',
  children,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = () => {
      if (
        modalRef.current &&
        !(modalRef.current as any).contains(event!.target)
      ) {
        onClickClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className={styles.modalWrapper} ref={modalRef}>
      <div className={styles.modal}>
        <div className={styles.modalHeaderWrapper}>
          <div className={styles.modalHeaderTitle}>{modalTitle}</div>
          <IconButton
            icon={<CloseIcon />}
            onClickAction={() => onClickClose()}
          />
        </div>

        {children && (
          <div className={styles.modalChildrenWrapper}>{children}</div>
        )}

        <div className={styles.buttonWrapper}>
          <Button
            type="secondary"
            text="Cancel"
            onClickAction={() => onClickClose()}
            isDisabled={false}
          />
          <Button
            type={confirmButtonType}
            text={confirmButtonTitle}
            onClickAction={() => onClickConfirm()}
            isDisabled={confirmButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal;
