import React from 'react';
import classNames from 'classnames';
import styles from './InputField.module.css';

interface InputFieldProps {
  text: string;
  onChangeAction: (input: string) => void;
  placeholder?: string;
  error?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  text,
  onChangeAction,
  placeholder,
  error,
}) => {
  return (
    <div className={styles.inputFieldWrapper}>
      <input
        className={classNames({
          [styles.inputField]: true,
          [styles.inputField_error]: !!error,
        })}
        type="text"
        value={text}
        onChange={(event) => onChangeAction(event.target.value)}
        placeholder={placeholder}
      />
      {error && <span className={styles.inputFieldErrorMessage}>{error}</span>}
    </div>
  );
};

export default InputField;
