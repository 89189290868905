import { instance } from './api';

export interface Stopword {
  id: string;
  word: string;
}

export const apiStopwords = {
  getStopwords: () => {
    return instance.get('/stopwords');
  },
  deleteStopword: (stopwordId: string) => {
    return instance.delete(`/stopwords/${stopwordId}`);
  },
  editStopword: (stopword: Stopword) => {
    return instance.patch(`/stopwords/${stopword.id}`, {
      word: stopword.word,
    });
  },
  postStopword: (word: string) => {
    return instance.post('/stopwords', { word });
  },
};
