import React from 'react';
import IconButton from '../IconButton/IconButton';
import CloseIcon from '../Icons/Close';

import styles from './ListItem.module.css';

interface ListItemProps {
  text: string;
  onClickAction: () => void;
}

const ListItem: React.FC<ListItemProps> = ({ text, onClickAction }) => {
  return (
    <div className={styles.listItemWrapper}>
      <span>{text}</span>
      <IconButton icon={<CloseIcon />} onClickAction={onClickAction} />
    </div>
  );
};

export default ListItem;
