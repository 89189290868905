import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Intent, IntentRequest } from '../../api/api-intents';
import {
  createIntent,
  editIntent,
  getStatus,
  intentIsValid,
  validateIntent,
} from '../../slices/intentsSlice';
import { AppDispatch } from '../../store';
import Button from '../Button/Button';
import Selection from '../Selection/Selection';
import ListItem from '../ListItem/ListItem';
import Modal from '../Modal/Modal';
import PageHeader from '../PageHeader/PageHeader';
import TextArea from '../TextArea/TextArea';

import styles from './IntentModal.module.css';

interface IntentProps {
  type: 'create' | 'edit';
  intent: IntentRequest | Intent;
  removeValue: (value: string, key: 'utterance' | 'answer') => void;
  setValue: (value: string, key: 'utterance' | 'answer') => void;
  closeModal: () => void;
}

const IntentModal: React.FC<IntentProps> = ({
  type,
  intent,
  setValue,
  removeValue,
  closeModal,
}) => {
  const title = type === 'create' ? 'Create intent' : 'Edit intent';
  const [currentUtteranceValue, setCurrentUtteranceValue] = useState('');
  const [currentAnswerValue, setCurrentAnswerValue] = useState('');
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [addingValue, setAddingValue] = useState({
    requested: false,
    key: 'utterance',
  });
  const [error, setError] = useState({ message: '', key: 'utterance' });
  const [intentType, setIntentType] = useState<'common' | 'shop'>(
    intent.intentType ?? 'common',
  );
  const newIntentIsValid = useSelector(intentIsValid);
  const status = useSelector(getStatus);

  const dispatch = useDispatch<AppDispatch>();

  const valueExists = (value: string, key: 'utterance' | 'answer') => {
    if (key === 'utterance') {
      return intent.utterances.includes(value);
    }

    return intent.answers.includes(value);
  };

  useEffect(() => {
    if (newIntentIsValid && addingValue.requested && status !== 'loading') {
      if (addingValue.key === 'utterance') {
        setValue(currentUtteranceValue, 'utterance');
        setCurrentUtteranceValue('');
      } else {
        setValue(currentAnswerValue, 'answer');
        setCurrentAnswerValue('');
      }
      setAddingValue({ requested: false, key: 'utterance' });
    }
  }, [newIntentIsValid, addingValue, status]);

  return (
    <div className={styles.intentModalWrapper}>
      {showLeaveModal && (
        <Modal
          modalTitle="Are you sure you want to leave the page?"
          onClickConfirm={() => {
            setShowLeaveModal(false);
            closeModal();
          }}
          onClickClose={() => setShowLeaveModal(false)}
          confirmButtonTitle="Leave"
        />
      )}

      {showConfirmationModal && (
        <Modal
          modalTitle="Do you want to save the changes you made?"
          onClickConfirm={() => {
            if (type === 'create') {
              dispatch(createIntent({ ...intent, intentType }));
            } else {
              // For "edit", we know that an id exists
              dispatch(editIntent({ ...intent, intentType } as Intent));
            }
            setShowLeaveModal(false);
            closeModal();
          }}
          onClickClose={() => setShowLeaveModal(false)}
          confirmButtonTitle={type === 'create' ? 'Add intent' : 'Edit intent'}
        />
      )}
      <PageHeader />

      <div className="appContent">
        <h2 className={styles.intentModalTitle}>{title}</h2>
        <div className={styles.intentTypeSelection}>
          <Selection
            values={[
              { key: 'Common', value: 'common' },
              { key: 'Shop', value: 'shop' },
            ]}
            activeSelection={intentType}
            setActiveSelection={setIntentType}
          />
        </div>

        <div className={styles.intentHeaderWrapper}>
          <div className={styles.intentHeader}>Questions</div>
          <div className={styles.intentHeader}>Answers</div>
        </div>

        <div className={styles.intentBody}>
          <div>
            {/* Utterances */}

            <TextArea
              text={currentUtteranceValue}
              setText={(newText: string) => setCurrentUtteranceValue(newText)}
              onClickAction={() => {
                if (valueExists(currentUtteranceValue, 'utterance')) {
                  setError({
                    message:
                      'The question already exists. Please enter a different question.',
                    key: 'utterance',
                  });
                } else {
                  setAddingValue({ requested: true, key: 'utterance' });
                  setError({ message: '', key: 'utterance' });
                  dispatch(validateIntent(currentUtteranceValue));
                }
              }}
              error={
                !newIntentIsValid ||
                (error.message && error.key === 'utterance')
                  ? 'The question already exists. Please enter a different question.'
                  : ''
              }
            />
            {intent.utterances?.map((utterance, i) => (
              <div className={styles.intentListItemWrapper} key={i}>
                <ListItem
                  text={utterance}
                  onClickAction={() => removeValue(utterance, 'utterance')}
                />
              </div>
            ))}
          </div>

          <div>
            {/* Answers */}

            <TextArea
              text={currentAnswerValue}
              setText={(newText: string) => setCurrentAnswerValue(newText)}
              onClickAction={() => {
                setAddingValue({ requested: true, key: 'answer' });
              }}
            />
            {intent.answers?.map((answer, i) => (
              <div className={styles.intentListItemWrapper} key={i}>
                <ListItem
                  text={answer}
                  onClickAction={() => removeValue(answer, 'answer')}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            type="secondary"
            text="Cancel"
            onClickAction={() => setShowLeaveModal(true)}
            isDisabled={false}
          />
          <Button
            type="primary"
            text={type === 'create' ? 'Add intent' : 'Edit intent'}
            onClickAction={() => setShowConfirmationModal(true)}
            isDisabled={
              !newIntentIsValid ||
              intent.answers.length === 0 ||
              intent.utterances.length === 0
            }
          />
        </div>
      </div>
    </div>
  );
};

export default IntentModal;
