import classNames from 'classnames';
import React from 'react';

import styles from './Button.module.css';

interface ButtonProps {
  type: 'primary' | 'secondary' | 'error';
  text: string;
  onClickAction: () => void;
  isDisabled: boolean;
  icon?: React.ReactElement;
}

const Button: React.FC<ButtonProps> = ({
  type,
  text,
  onClickAction,
  isDisabled,
  icon,
}) => {
  return (
    <button
      disabled={isDisabled}
      className={classNames({
        [styles.buttonWrapper]: true,
        [styles.buttonWrapper_primary]: type === 'primary',
        [styles.buttonWrapper_secondary]: type === 'secondary',
        [styles.buttonWrapper_error]: type === 'error',
        [styles.buttonWrapper_disabled]: isDisabled,
      })}
      onClick={onClickAction}
    >
      {icon && <div className={styles.buttonIcon}>{icon}</div>}
      {text}
    </button>
  );
};

export default Button;
