import React from 'react';

export const Triangle = () => (
  <svg
    width="62"
    height="60"
    viewBox="0 0 62 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_31_280)">
      <path
        d="M29.2841 17.866C30.0605 16.5691 31.9395 16.5691 32.7159 17.866L41.3118 32.2226C42.1099 33.5557 41.1496 35.25 39.5958 35.25H22.4042C20.8504 35.25 19.8901 33.5557 20.6882 32.2226L29.2841 17.866Z"
        fill="#132631"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_31_280"
        x="0.400879"
        y="0.893311"
        width="61.1982"
        height="58.3567"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_31_280"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_31_280"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
