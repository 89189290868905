import React from 'react';
import styles from './Selection.module.css';

interface SelectionProps {
  values: { key: string; value: string }[];
  setActiveSelection: (value: 'common' | 'shop') => void;
  activeSelection?: string;
}

const Selection: React.FC<SelectionProps> = ({
  values,
  activeSelection,
  setActiveSelection,
}) => {
  return (
    <div>
      <form
        onChange={(e: any) => {
          setActiveSelection(e.target.value);
        }}
      >
        {values.map(({ key, value }) => (
          <>
            <label htmlFor={value} className={styles.selectionOption}>
              {key}
            </label>
            <input
              type="radio"
              id={value}
              name={value}
              value={value}
              checked={activeSelection === value}
            />
          </>
        ))}
      </form>
    </div>
  );
};

export default Selection;
