import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import CloseIcon from '../Icons/Close';
import RangeSlider from '../RangeSlider/RangeSlider';
import Selection from '../Selection/Selection';
import Notification from '../Notification/Notification';

import styles from './SettingsModal.module.css';
import InputField from '../InputField/InputField';
import {
  apiSttTtsOptions,
  ChatOptions,
  SttOptions,
  TtsOptions,
} from '../../api/api-stt-tts-options';

interface Props {
  close: () => void;
}

const SettingsModal: React.FC<Props> = ({ close }) => {
  const [ttsSpeakingRate, setTtsSpeakingRate] = useState(1.0);
  const [voiceName, setVoiceName] = useState('');
  const [micIdleTimeInSec, setMicIdleTimeInSec] = useState(0);
  const [noResponseMessage, setNoResponseMessage] = useState('');
  const [sttTtsOptions, setSttTtsOptions] = useState<
    { stt: SttOptions; tts: TtsOptions; chat: ChatOptions } | undefined
  >();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationType, setNotificationType] = useState<'success' | 'error'>(
    'success',
  );

  useEffect(() => {
    const fetchSttTtsOptions = async () => {
      const options = await apiSttTtsOptions.getSttTtsOptions();
      return options.data.options;
    };

    fetchSttTtsOptions().then((response) => {
      setTtsSpeakingRate(
        Number.parseFloat(response.tts.audioConfig.speakingRate),
      );
      setVoiceName(response.tts.voice.name);
      setMicIdleTimeInSec(
        response.chat.micIdleTimeInMs !== 0
          ? response.chat.micIdleTimeInMs / 1000
          : 0,
      );
      setNoResponseMessage(response.chat.noResponseMessage);
      setSttTtsOptions(response);
    });
  }, []);

  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  }, [showNotification]);

  const updateOptions = async () => {
    try {
      sttTtsOptions &&
        (await apiSttTtsOptions.updateSttTtsOptions({
          ...sttTtsOptions,
          chat: {
            ...sttTtsOptions.chat,
            micIdleTimeInMs: (micIdleTimeInSec * 1000).toString(),
            noResponseMessage,
          },
          tts: {
            ...sttTtsOptions.tts,
            audioConfig: {
              ...sttTtsOptions.tts.audioConfig,
              speakingRate: ttsSpeakingRate.toString(),
            },
            voice: {
              ...sttTtsOptions.tts.voice,
              name: voiceName,
            },
          },
        }));

      setNotificationType('success');
      setShowNotification(true);
    } catch (error) {
      setNotificationType('error');
      setShowNotification(true);
    }
  };

  return (
    <div className={styles.settingsModalWrapper}>
      <div className={styles.settingsHeaderWrapper}>
        <h2 className={styles.settingsTitle}>Settings</h2>
        <div onClick={() => close()}>
          <CloseIcon />
        </div>
      </div>

      <div className={styles.settingsModalBody}>
        <div className={styles.settingsOptionWrapper}>
          <label
            htmlFor="ttsSpeakingRate"
            className={styles.settingsOptionLabel}
          >
            TTS speaking rate:
          </label>

          <input
            step={0.01}
            min={0.25}
            max={4.0}
            onKeyDown={(e) =>
              !(e.keyCode === 38 || e.keyCode === 40) && e.preventDefault()
            }
            className={styles.settings_ttsSpeakingRateInput}
            id="ttsSpeakingRate"
            type="number"
            value={ttsSpeakingRate}
            onChange={(e) =>
              setTtsSpeakingRate(Number.parseFloat(e.target.value))
            }
          />
        </div>

        <div className={styles.settingsOptionWrapper}>
          <span className={styles.settingsOptionLabel}>Voice setting:</span>
          <Selection
            values={[
              { key: 'Female', value: 'ko-KR-Standard-A' },
              { key: 'Male', value: 'ko-KR-Standard-D' },
            ]}
            activeSelection={voiceName}
            setActiveSelection={setVoiceName}
          />
        </div>

        <div className={styles.settingsOptionWrapper}>
          <span className={styles.settingsOptionLabel}>
            Mic idle time in seconds:
          </span>
          <RangeSlider
            currentValue={micIdleTimeInSec}
            setValue={setMicIdleTimeInSec}
            step={1}
            min={0}
            max={10}
          />
        </div>

        <div className={styles.settingsOptionWrapper}>
          <span className={styles.settingsOptionLabel}>
            No response message:
          </span>

          <InputField
            text={noResponseMessage}
            onChangeAction={(input: string) => setNoResponseMessage(input)}
            placeholder="Enter a message..."
          />
        </div>
      </div>
      <div className={styles.settingsSubmitButton}>
        <Button
          type="primary"
          onClickAction={() => updateOptions()}
          text="Submit"
          isDisabled={false}
        />
      </div>
      {showNotification && (
        <Notification
          type={notificationType}
          text={
            notificationType === 'success'
              ? 'Settings were successfully updated.'
              : 'Settings could not be updated. Please try again later.'
          }
        />
      )}
    </div>
  );
};

export default SettingsModal;
