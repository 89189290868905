import React from 'react';

export const Ellipse = () => (
  <svg
    width="19"
    height="5"
    viewBox="0 0 19 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.84668 2.47656C0.84668 2.0026 1.00749 1.60482 1.3291 1.2832C1.65072 0.953125 2.08659 0.788086 2.63672 0.788086C3.19531 0.788086 3.63118 0.953125 3.94434 1.2832C4.26595 1.60482 4.42676 2.0026 4.42676 2.47656C4.42676 2.95052 4.26595 3.34831 3.94434 3.66992C3.63118 3.99154 3.19531 4.15234 2.63672 4.15234C2.08659 4.15234 1.65072 3.99154 1.3291 3.66992C1.00749 3.34831 0.84668 2.95052 0.84668 2.47656ZM8.08301 2.47656C8.08301 2.0026 8.24382 1.60482 8.56543 1.2832C8.88704 0.953125 9.32292 0.788086 9.87305 0.788086C10.4316 0.788086 10.8675 0.953125 11.1807 1.2832C11.5023 1.60482 11.6631 2.0026 11.6631 2.47656C11.6631 2.95052 11.5023 3.34831 11.1807 3.66992C10.8675 3.99154 10.4316 4.15234 9.87305 4.15234C9.32292 4.15234 8.88704 3.99154 8.56543 3.66992C8.24382 3.34831 8.08301 2.95052 8.08301 2.47656ZM15.3193 2.47656C15.3193 2.0026 15.4801 1.60482 15.8018 1.2832C16.1234 0.953125 16.5592 0.788086 17.1094 0.788086C17.668 0.788086 18.1038 0.953125 18.417 1.2832C18.7386 1.60482 18.8994 2.0026 18.8994 2.47656C18.8994 2.95052 18.7386 3.34831 18.417 3.66992C18.1038 3.99154 17.668 4.15234 17.1094 4.15234C16.5592 4.15234 16.1234 3.99154 15.8018 3.66992C15.4801 3.34831 15.3193 2.95052 15.3193 2.47656Z"
      fill="#2A3559"
    />
  </svg>
);
