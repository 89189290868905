import { configureStore } from '@reduxjs/toolkit';
import intentsReducer from './slices/intentsSlice';
import stopwordsReducer from './slices/stopwordsSlice';

export const store = configureStore({
  reducer: {
    intents: intentsReducer,
    stopwords: stopwordsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
