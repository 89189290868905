import React from 'react';
import { Search } from '../Icons/Search';

import styles from './SearchBar.module.css';

interface SearchBarProps {
  text: string;
  setText: (newText: string) => void;
  placeholder: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  text,
  setText,
  placeholder,
}) => {
  return (
    <div className={styles.searchBarWrapper}>
      <Search fill="#ACB4CE" />
      <input
        className={styles.searchBarText}
        placeholder={placeholder}
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
    </div>
  );
};

export default SearchBar;
