import React from 'react';
import { Triangle } from '../Icons/Triangle';

import styles from './Tooltip.module.css';

interface TooltipProps {
  text: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text }) => {
  return (
    <div className={styles.tooltipWrapper}>
      <div className={styles.tooltipTriangle}>
        <Triangle />
      </div>
      <div className={styles.tooltipText}>{text}</div>
    </div>
  );
};

export default Tooltip;
