import { instance } from './api';

export interface TtsOptions {
  voice: { languageCode: string; name: string };
  audioConfig: { audioEncoding: string; speakingRate: string };
}

export interface ChatOptions {
  micIdleTimeInMs: string;
  noResponseMessage: string;
}

export interface SttOptions {
  encoding: string;
  languageCode: string;
}

export const apiSttTtsOptions = {
  getSttTtsOptions: () => {
    return instance.get(
      '/stt-tts-options/5226094b-b227-4402-bb7e-6a597ce21cf6',
    );
  },
  updateSttTtsOptions: (sttTtsOptions: {
    stt: SttOptions;
    tts: TtsOptions;
    chat: ChatOptions;
  }) => {
    return instance.patch(
      '/stt-tts-options/5226094b-b227-4402-bb7e-6a597ce21cf6',
      {
        stt: sttTtsOptions.stt,
        tts: sttTtsOptions.tts,
        chat: sttTtsOptions.chat,
      },
    );
  },
};
