import React from 'react';
import classNames from 'classnames';
import styles from './IconButton.module.css';

interface IconButtonProps {
  icon: React.ReactElement;
  onClickAction: () => void;
  fill?: boolean;
  isDisabled?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClickAction,
  fill = false,
  isDisabled = false,
}) => {
  return (
    <button
      className={classNames({
        [styles.iconButtonWrapper]: true,
        [styles.iconButtonWrapper_fill]: fill,
        [styles.iconButtonWrapper_disabled]: isDisabled,
      })}
      onClick={onClickAction}
      disabled={isDisabled}
    >
      {icon}
    </button>
  );
};

export default IconButton;
