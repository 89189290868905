import React from 'react';
import Logo from '../Icons/Logo';

import styles from './PageHeader.module.css';

interface PageHeaderProps {
  buttons?: any;
}

const PageHeader: React.FC<PageHeaderProps> = ({ buttons }) => {
  return (
    <div className={styles.pageHeader}>
      <Logo />
      {buttons}
    </div>
  );
};

export default PageHeader;
