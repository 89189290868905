import React from 'react';
import { Check } from '../Icons/Check';
import Close from '../Icons/Close';
import styles from './Notification.module.css';

interface NotificationProps {
  type: 'success' | 'error';
  text: string;
}

const Notification: React.FC<NotificationProps> = ({ type, text }) => {
  const icon =
    type === 'success' ? <Check /> : <Close fill="#E56161" opacity="1" />;

  return (
    <div className={styles.notificationWrapper}>
      <div className={styles.notificationIcon}>{icon}</div>

      <span className={styles.notificationText}>{text}</span>
    </div>
  );
};

export default Notification;
