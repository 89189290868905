import React from 'react';

export const Chevron: React.FC<{ position: 'up' | 'down' }> = ({
  position,
}) => {
  return position === 'down' ? (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 10.75C8.64844 10.75 8.33594 10.6328 8.10156 10.3984L0.601562 2.89844C0.09375 2.42969 0.09375 1.60938 0.601562 1.14062C1.07031 0.632812 1.89062 0.632812 2.35938 1.14062L9 7.74219L15.6016 1.14062C16.0703 0.632812 16.8906 0.632812 17.3594 1.14062C17.8672 1.60938 17.8672 2.42969 17.3594 2.89844L9.85938 10.3984C9.625 10.6328 9.3125 10.75 9 10.75Z"
        fill="#2A82B3"
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.250001C9.35156 0.250001 9.66406 0.367188 9.89844 0.601563L17.3984 8.10156C17.9062 8.57031 17.9062 9.39063 17.3984 9.85938C16.9297 10.3672 16.1094 10.3672 15.6406 9.85938L9 3.25781L2.39844 9.85938C1.92969 10.3672 1.10937 10.3672 0.640624 9.85938C0.132812 9.39063 0.132812 8.57031 0.640624 8.10156L8.14062 0.601563C8.375 0.367188 8.6875 0.250001 9 0.250001Z"
        fill="#2A82B3"
      />
    </svg>
  );
};
