/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Intent } from '../api/api-intents';
import Button from '../components/Button/Button';
import { Pen } from '../components/Icons/Pen';
import Plus from '../components/Icons/Plus';
import { Search } from '../components/Icons/Search';
import { Trash } from '../components/Icons/Trash';
import InputField from '../components/InputField/InputField';
import IntentModal from '../components/IntentModal/IntentModal';
import IntentRow from '../components/IntentRow/IntentRow';
import Selection from '../components/Selection/Selection';
import Modal from '../components/Modal/Modal';
import PageHeader from '../components/PageHeader/PageHeader';
import Pagination from '../components/Pagination/Pagination';
import SearchBar from '../components/SearchBar/SearchBar';
import SettingsModal from '../components/SettingsModal/SettingsModal';
import {
  deleteIntent,
  fetchIntents,
  getStatus,
  getTotalCount,
  intentIsValid,
  selectAllIntents,
  validateIntent,
} from '../slices/intentsSlice';
import { AppDispatch } from '../store';

import styles from './IntentsPage.module.css';
import classNames from 'classnames';
import StopwordsList from '../components/StopwordsList/StopwordsList';

const NUMBER_OF_ROWS = '20';

const IntentsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [allIntents, setAllIntents] = useState<Intent[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showCreateIntentModal, setShowCreateIntentModal] = useState(false);
  const [showEditIntentModal, setShowEditIntentModal] = useState(false);
  const [showDeleteIntentModal, setShowDeleteIntentModal] = useState(false);
  const [currentIntent, setCurrentIntent] = useState<Intent>({
    id: '',
    name: '',
    utterances: [],
    answers: [],
  });
  const [createIntentWasRequested, setCreateIntentWasRequested] =
    useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState<'intents' | 'stopwords'>(
    'intents',
  );
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
  const [intentType, setIntentType] = useState<'common' | 'shop' | undefined>();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const intents = useSelector(selectAllIntents);
  const totalCount = useSelector(getTotalCount);
  const newIntentIsValid = useSelector(intentIsValid);
  const status = useSelector(getStatus);

  useEffect(() => {
    dispatch(
      fetchIntents({
        pagination: { currentPage: '1', numberOfRows: NUMBER_OF_ROWS },
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      fetchIntents({
        pagination: {
          currentPage: currentPage.toString(),
          numberOfRows: NUMBER_OF_ROWS,
        },
      }),
    );
  }, [currentPage]);

  useEffect(() => {
    setAllIntents(intents);
  }, [intents]);

  useEffect(() => {
    const numberOfPages = Math.ceil(
      totalCount / Number.parseInt(NUMBER_OF_ROWS),
    );
    setTotalNumberOfPages(numberOfPages);
  }),
    [totalCount];

  useEffect(() => {
    if (newIntentIsValid && createIntentWasRequested && status !== 'loading') {
      setShowCreateIntentModal(true);
      setShowModal(false);
      setCreateIntentWasRequested(false);
    }
  }, [newIntentIsValid, createIntentWasRequested, status]);

  useEffect(() => {
    if (searchValue === '') {
      dispatch(fetchIntents({}));
    } else {
      dispatch(fetchIntents({ searchBy: searchValue }));
    }
  }, [searchValue]);

  useEffect(() => {
    if (intentType) {
      dispatch(fetchIntents({ searchBy: searchValue, intentType }));
    }
  }, [intentType]);

  const setValue = (value: string, key: 'utterance' | 'answer') => {
    if (key === 'utterance') {
      setCurrentIntent({
        ...currentIntent,
        utterances: [...currentIntent.utterances, value],
      });
    } else {
      setCurrentIntent({
        ...currentIntent,
        answers: [...currentIntent.answers, value],
      });
    }
  };

  const removeValue = (value: string, key: 'utterance' | 'answer') => {
    if (key === 'utterance') {
      const newUtterances = currentIntent.utterances.filter(
        (utterance) => utterance !== value,
      );
      setCurrentIntent({
        ...currentIntent,
        utterances: newUtterances,
      });
    } else {
      const newAnswers = currentIntent.answers.filter(
        (answer) => answer !== value,
      );
      setCurrentIntent({
        ...currentIntent,
        answers: newAnswers,
      });
    }
  };

  const resetState = () => {
    setShowModal(false);
    setCurrentIntent({
      id: '',
      name: '',
      utterances: [],
      answers: [],
    });
    setShowCreateIntentModal(false);
    setShowEditIntentModal(false);
  };

  const NoResults = () => {
    return (
      <div className={styles.intentsPage_noResultsWrapper}>
        <div className={styles.intentsPage_noResultsIcon}>
          <Search />
        </div>
        <div className={styles.intentsPage_noResultsHeader}>
          No matching intents
        </div>
        <p className={styles.intentsPage_noResultsText}>
          No results were found for your search. Please enter a different name,
          question or answer.
        </p>
      </div>
    );
  };

  return (
    <>
      <PageHeader
        buttons={
          <div className={styles.intentsPage_pageHeaderButtonWrapper}>
            <Button
              type="primary"
              text="Settings"
              onClickAction={() => setShowSettingsModal(true)}
              isDisabled={false}
            />
            <Button
              type="primary"
              text="Chatbot"
              onClickAction={() => {
                window.location.replace(
                  process.env.REACT_APP_CHATBOT_URL || '',
                );
              }}
              isDisabled={false}
            />
          </div>
        }
      />

      <div className="appContent">
        {showModal && (
          <Modal
            modalTitle="Set name for intent"
            onClickClose={() => resetState()}
            onClickConfirm={() => {
              setCreateIntentWasRequested(true);
              dispatch(validateIntent(currentIntent.name));
            }}
            confirmButtonTitle="Create"
            confirmButtonDisabled={!currentIntent.name}
          >
            <InputField
              text={currentIntent.name}
              onChangeAction={(newName: string) =>
                setCurrentIntent({ ...currentIntent, name: newName })
              }
              error={
                !newIntentIsValid
                  ? 'The name already exists. Please enter a different name.'
                  : ''
              }
            />
          </Modal>
        )}

        {/* Create intent */}
        {showCreateIntentModal && (
          <IntentModal
            type="create"
            intent={currentIntent}
            setValue={setValue}
            removeValue={removeValue}
            closeModal={() => resetState()}
          />
        )}

        {/* Edit intent */}
        {showEditIntentModal && (
          <IntentModal
            type="edit"
            intent={currentIntent}
            setValue={setValue}
            removeValue={removeValue}
            closeModal={() => resetState()}
          />
        )}

        {/* Delete intent */}
        {showDeleteIntentModal && (
          <Modal
            modalTitle="Delete intent?"
            onClickClose={() => setShowDeleteIntentModal(false)}
            onClickConfirm={() => {
              dispatch(deleteIntent(currentIntent.id));
              setShowDeleteIntentModal(false);
            }}
            confirmButtonTitle="Delete"
            confirmButtonType="error"
          >
            <div>You cannot undo this action.</div>
          </Modal>
        )}

        {showSettingsModal && (
          <SettingsModal close={() => setShowSettingsModal(false)} />
        )}

        <h2 className={styles.intentsPage_header}>Corpus Management</h2>

        <ul className={styles.listTabs}>
          <li
            className={classNames({
              [styles.listTab]: true,
              [styles.listTab_active]: currentTab === 'intents',
            })}
            onClick={() => setCurrentTab('intents')}
          >
            Intents
          </li>
          <li
            className={classNames({
              [styles.listTab]: true,
              [styles.listTab_active]: currentTab === 'stopwords',
            })}
            onClick={() => setCurrentTab('stopwords')}
          >
            Stopwords
          </li>
        </ul>

        {currentTab === 'intents' ? (
          <>
            <div className={styles.intentsPage_filterWrapper}>
              <SearchBar
                text={searchValue}
                setText={setSearchValue}
                placeholder="Enter full name, question or answer..."
              />

              <Selection
                values={[
                  { key: 'Common', value: 'common' },
                  { key: 'Shop', value: 'shop' },
                ]}
                activeSelection={intentType}
                setActiveSelection={setIntentType}
              />

              <Button
                type="primary"
                text="Add intent"
                onClickAction={() => {
                  setShowModal(true);
                }}
                isDisabled={false}
                icon={<Plus />}
              />
            </div>
            <div className={styles.intentsPage_intentsTable}>
              {searchValue && intents.length === 0 ? (
                <NoResults />
              ) : (
                <>
                  <div className={styles.intentsPage_intentsTableHeaderWrapper}>
                    <div className={styles.intentsPage_intentsTableHeader}>
                      Name
                    </div>
                    <div className={styles.intentsPage_intentsTableHeader}>
                      Questions
                    </div>
                    <div className={styles.intentsPage_intentsTableHeader}>
                      Answers
                    </div>
                    <div
                      className={styles.intentsPage_intentsTableHeader}
                    ></div>
                  </div>

                  {intents.length > 0 ? (
                    <>
                      <div className={styles.intentsPage_intentsTableBody}>
                        {allIntents.map((intent, i) => {
                          return (
                            <IntentRow
                              key={i}
                              intent={intent}
                              actions={[
                                {
                                  icon: <Pen />,
                                  name: 'Edit',
                                  onClickAction: () => {
                                    setCurrentIntent(intent);
                                    setShowEditIntentModal(true);
                                  },
                                },
                                {
                                  icon: <Trash />,
                                  name: 'Delete',
                                  onClickAction: () => {
                                    setCurrentIntent(intent);
                                    setShowDeleteIntentModal(true);
                                  },
                                },
                              ]}
                            />
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className={styles.intentsPage_noIntents}>
                      No intents were defined yet.
                    </div>
                  )}
                </>
              )}
            </div>
            {intents.length > 0 && (
              <Pagination
                currentPage={currentPage}
                onPageChange={(newPage: number) => setCurrentPage(newPage)}
                totalNumberOfPages={totalNumberOfPages}
              />
            )}
          </>
        ) : (
          <StopwordsList />
        )}
      </div>
    </>
  );
};

export default IntentsPage;
