import React from 'react';
import classNames from 'classnames';
import IconButton from '../IconButton/IconButton';
import PlusIcon from '../Icons/Plus';
import styles from './TextArea.module.css';

interface TextAreaProps {
  text: string;
  onClickAction: () => void;
  setText: (text: string) => void;
  error?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  text,
  onClickAction,
  setText,
  error,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inputTextAreaWrapper}>
        <textarea
          cols={30}
          className={classNames({
            [styles.inputTextArea]: true,
            [styles.inputTextArea_error]: !!error,
          })}
          onChange={(event) => setText(event.target.value)}
          value={text}
        />

        <div className={styles.inputAddButton}>
          <IconButton
            icon={<PlusIcon />}
            onClickAction={onClickAction}
            fill
            isDisabled={!text}
          />
        </div>
      </div>

      {error && <span className={styles.inputError}>{error}</span>}
    </div>
  );
};

export default TextArea;
