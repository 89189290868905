import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Intent } from '../../api/api-intents';
import DropdownMenu, { ActionMenuItem } from '../DropdownMenu/DropdownMenu';
import { Chevron } from '../Icons/Chevron';
import Tooltip from '../Tooltip/Tooltip';

import styles from './IntentRow.module.css';

interface IntentRowProps {
  intent: Intent;
  actions: ActionMenuItem[];
}

const IntentsListCellItem: React.FC<{
  index: number;
  data: string;
  isExpanded: boolean;
}> = ({ index, data, isExpanded }) => {
  return (
    <div className={styles.intentListCellItemValues}>
      <span className={styles.intentsListCellIndex}>{`${index}.`}</span>
      <span className={styles.intentsListCellData}>{data}</span>
      {!isExpanded && <span className={styles.intentRowCell_blur} />}
    </div>
  );
};

const IntentsListCell: React.FC<{
  cellListData: string[];
  isExpanded: boolean;
}> = ({ cellListData, isExpanded }) => {
  return (
    <div
      className={classNames({
        [styles.intentsListCellWrapper]: true,
        [styles.intentsListCellWrapper_wrap]: isExpanded,
      })}
    >
      <IntentsListCellItem
        index={1}
        data={cellListData[0]}
        isExpanded={isExpanded}
      />

      {isExpanded && (
        <div>
          {cellListData.slice(1).map((data, i) => (
            <div key={i} className={styles.intentListCellItem}>
              <IntentsListCellItem
                index={i + 2}
                data={data}
                isExpanded={isExpanded}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const IntentRow: React.FC<IntentRowProps> = ({ intent, actions }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showNameTooltip, setShowNameTooltip] = useState(false);

  const nameRef = useRef(null);

  const valueIsTooLong = (ref: React.MutableRefObject<any>): boolean => {
    if (ref.current) {
      const { clientWidth, scrollWidth } = ref.current;
      return clientWidth + 1 < scrollWidth;
    }

    return false;
  };

  return (
    <div className={styles.intentRowWrapper}>
      <div
        ref={nameRef}
        className={styles.intentRowName}
        onMouseOver={() => {
          if (valueIsTooLong(nameRef)) {
            setShowNameTooltip(true);
          }
        }}
        onMouseLeave={() => setShowNameTooltip(false)}
      >
        {intent.name}
        <span className={styles.intentRowCell_blur} />
      </div>
      {showNameTooltip && (
        <div className={styles.intentRowTooltip}>
          <Tooltip text={intent.name} />
        </div>
      )}
      <IntentsListCell
        cellListData={intent.utterances}
        isExpanded={isExpanded}
      />
      <IntentsListCell cellListData={intent.answers} isExpanded={isExpanded} />

      <div className={styles.intentRowActionWrapper}>
        <div
          className={styles.intentRowArrow}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Chevron position={isExpanded ? 'up' : 'down'} />
        </div>
        <DropdownMenu actions={actions} />
      </div>
    </div>
  );
};

export default IntentRow;
