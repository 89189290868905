import React from 'react';

export const Pen = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4023 2.00781L16.2422 0.847656C15.8555 0.460938 15.3633 0.25 14.8711 0.25C14.3438 0.25 13.8516 0.460938 13.4648 0.847656L5.87109 8.44141C5.58984 8.6875 5.41406 9.03906 5.34375 9.39062L4.5 13.1875C4.42969 13.5039 4.67578 13.75 4.95703 13.75C4.99219 13.75 4.99219 13.75 5.02734 13.75C5.02734 13.75 7.62891 13.2227 8.82422 12.9414C9.17578 12.8359 9.49219 12.6602 9.73828 12.4141L17.3672 4.78516C18.1758 4.01172 18.1758 2.78125 17.4023 2.00781ZM16.207 3.58984L8.57812 11.2188C8.54297 11.2539 8.50781 11.2891 8.47266 11.2891C7.98047 11.3945 7.24219 11.5703 6.53906 11.7109L6.99609 9.77734C6.99609 9.70703 7.03125 9.67188 7.06641 9.63672L14.6602 2.04297C14.7305 1.97266 14.8008 1.9375 14.8711 1.9375C14.9062 1.9375 14.9766 1.97266 15.0469 2.04297L16.207 3.20312C16.3125 3.30859 16.3125 3.48438 16.207 3.58984ZM14.9062 10.375C14.4141 10.375 14.0625 10.7617 14.0625 11.2188V15.7188C14.0625 16.2109 13.6758 16.5625 13.2188 16.5625H2.53125C2.03906 16.5625 1.6875 16.2109 1.6875 15.7188V5.03125C1.6875 4.57422 2.03906 4.1875 2.53125 4.1875H7.59375C8.05078 4.1875 8.4375 3.83594 8.4375 3.34375C8.4375 2.88672 8.05078 2.5 7.59375 2.5H2.49609C1.125 2.5 0 3.66016 0 5.03125V15.7188C0 17.125 1.125 18.25 2.49609 18.25H13.1484C14.5195 18.25 15.6445 17.125 15.6445 15.7188L15.75 11.2188C15.75 10.7617 15.3633 10.375 14.9062 10.375Z"
        fill="#6D758B"
      />
    </svg>
  );
};
