import React, { useEffect, useRef, useState } from 'react';
import { Dot } from '../Icons/Dot';

import styles from './DropdownMenu.module.css';

export interface ActionMenuItem {
  icon: React.ReactElement;
  name: string;
  onClickAction: () => void;
}

interface DropdownMenuProps {
  actions: ActionMenuItem[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ actions }) => {
  const [showMenu, setShowMenu] = useState(false);

  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = () => {
      if (
        wrapperRef.current &&
        !(wrapperRef.current as any).contains(event?.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div
      ref={wrapperRef}
      className={styles.dropdownMenuWrapper}
      onClick={() => setShowMenu(!showMenu)}
    >
      <Dot />
      <Dot />
      <Dot />

      {showMenu && (
        <div className={styles.dropdownMenu}>
          {actions.map((action, id) => (
            <div
              key={`action_${id}`}
              onClick={() => action.onClickAction()}
              className={styles.dropdownMenuAction}
            >
              {action.icon}
              <span className={styles.dropdownMenuActionName}>
                {action.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
