import React from 'react';
import { Range } from 'react-range';

import styles from './RangeSlider.module.css';

interface Props {
  currentValue: number;
  setValue: (newValue: number) => void;
  step?: number;
  min?: number;
  max?: number;
}

const RangeSlider: React.FC<Props> = ({
  currentValue,
  setValue,
  step = 0.1,
  min = 0,
  max = 100,
}) => {
  return (
    <div className={styles.rangeSliderWrapper}>
      <span className={styles.rangeSliderLabel}>{min}</span>
      <Range
        step={step}
        min={min}
        max={max}
        values={[currentValue]}
        onChange={(newValues) => {
          setValue(newValues[0]);
        }}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              // eslint-disable-next-line react/prop-types
              ...props.style,
              height: '6px',
              width: '100%',
              backgroundColor: '#EDEEF4',
              marginLeft: '8px',
              marginRight: '8px',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              // eslint-disable-next-line react/prop-types
              ...props.style,
              height: '18px',
              width: '18px',
              borderRadius: '50%',
              backgroundColor: '#2A82B2',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}
          >
            <div
              style={{
                position: 'absolute',
                bottom: '-20px',
                color: '#182039',
                fontWeight: 'bold',
                fontSize: '14px',
              }}
            >
              {currentValue}
            </div>
          </div>
        )}
      />
      <span className={styles.rangeSliderLabel}>{max}</span>
    </div>
  );
};

export default RangeSlider;
