import React from 'react';

export const Check = () => (
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.2957 2.2251C20.2957 2.62939 20.116 2.98877 19.8464 3.2583L8.34644 14.7583C8.0769 15.0278 7.71753 15.1626 7.35815 15.1626C6.95386 15.1626 6.59448 15.0278 6.32495 14.7583L0.574951 9.0083C0.30542 8.73877 0.170654 8.37939 0.170654 7.9751C0.170654 7.1665 0.799561 6.5376 1.60815 6.5376C1.96753 6.5376 2.3269 6.71729 2.59644 6.98682L7.35815 11.7036L17.825 1.23682C18.0945 0.967285 18.4539 0.787598 18.8582 0.787598C19.6218 0.787598 20.2957 1.4165 20.2957 2.2251Z"
      fill="#3EBD8F"
    />
  </svg>
);
