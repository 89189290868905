import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stopword } from '../../api/api-stopwords';
import {
  createStopword,
  deleteStopword,
  editStopword,
  fetchStopwords,
  selectAllStopwords,
} from '../../slices/stopwordsSlice';
import { AppDispatch } from '../../store';
import Button from '../Button/Button';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import { Pen } from '../Icons/Pen';
import Plus from '../Icons/Plus';
import { Trash } from '../Icons/Trash';
import InputField from '../InputField/InputField';
import Modal from '../Modal/Modal';

import styles from './StopwordsList.module.css';

const StopwordsList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const stopwords = useSelector(selectAllStopwords);
  const [currentStopword, setCurrentStopword] = useState<
    Stopword | undefined
  >();
  const [showStopwordModal, setShowStopwordModal] = useState(false);
  const [showDeleteStopwordModal, setShowDeleteStopwordModal] = useState(false);
  const [stopwordIsValid, setStopwordIsValid] = useState(true);
  const [currentAction, setCurrentAction] = useState<'create' | 'edit'>(
    'create',
  );

  const createOrEditStopword = async (
    value: string,
    actionType: 'create' | 'edit',
  ) => {
    const stopwordExists = !!stopwords.find(({ word }) => word === value);

    if (stopwordExists) {
      setStopwordIsValid(false);
      return;
    }

    setStopwordIsValid(true);

    if (actionType === 'create') {
      await dispatch(createStopword(value));
    } else {
      currentStopword && (await dispatch(editStopword(currentStopword)));
    }

    setCurrentStopword(undefined);
    setShowStopwordModal(false);
  };

  useEffect(() => {
    dispatch(fetchStopwords());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {showStopwordModal && (
        <Modal
          modalTitle={
            currentAction === 'create'
              ? 'Enter a stopword'
              : 'Modify the stopword'
          }
          onClickClose={() => {
            setShowStopwordModal(false);
            setCurrentStopword(undefined);
          }}
          onClickConfirm={() => {
            currentStopword &&
              createOrEditStopword(currentStopword.word, currentAction);
          }}
          confirmButtonTitle="Create"
          confirmButtonDisabled={!currentStopword?.word}
        >
          <InputField
            text={currentStopword?.word ?? ''}
            onChangeAction={(newWord: string) => {
              if (!currentStopword) {
                setCurrentStopword({ word: newWord, id: '' });
              } else {
                setCurrentStopword({ ...currentStopword, word: newWord });
              }
            }}
            error={
              !stopwordIsValid
                ? 'The word already exists. Please enter a different word.'
                : ''
            }
          />
        </Modal>
      )}
      {showDeleteStopwordModal && (
        <Modal
          modalTitle="Delete stopword?"
          onClickClose={() => setShowDeleteStopwordModal(false)}
          onClickConfirm={async () => {
            currentStopword &&
              (await dispatch(deleteStopword(currentStopword.id)));
            setShowDeleteStopwordModal(false);
            setCurrentStopword(undefined);
          }}
          confirmButtonTitle="Delete"
          confirmButtonType="error"
        >
          <div>You cannot undo this action.</div>
        </Modal>
      )}

      <div className={styles.stopwordsHeader}>
        <Button
          type="primary"
          text="Add stopword"
          onClickAction={() => {
            setShowStopwordModal(true);
          }}
          isDisabled={false}
          icon={<Plus />}
        />
      </div>

      <div className={styles.stopwordList}>
        {stopwords.map((stopword, i) => (
          <div key={i} className={styles.stopwordItem}>
            <div>{stopword.word}</div>
            <DropdownMenu
              actions={[
                {
                  icon: <Pen />,
                  name: 'Edit',
                  onClickAction: () => {
                    setCurrentStopword(stopword);
                    setCurrentAction('edit');
                    setShowStopwordModal(true);
                  },
                },
                {
                  icon: <Trash />,
                  name: 'Delete',
                  onClickAction: () => {
                    setCurrentStopword(stopword);
                    setShowDeleteStopwordModal(true);
                  },
                },
              ]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default StopwordsList;
