import React from 'react';

export const Search: React.FC<{ fill?: string }> = ({ fill = '#130F2D' }) => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.5625 26.2578L20.2344 18.9297C21.8203 17.0156 22.6953 14.5547 22.6953 11.875C22.6953 5.64062 17.5547 0.5 11.3203 0.5C5.03125 0.5 0 5.64062 0 11.875C0 18.1641 5.08594 23.25 11.3203 23.25C13.9453 23.25 16.4062 22.375 18.375 20.7891L25.7031 28.1172C25.9766 28.3906 26.3047 28.5 26.6875 28.5C27.0156 28.5 27.3438 28.3906 27.5625 28.1172C28.1094 27.625 28.1094 26.8047 27.5625 26.2578ZM2.625 11.875C2.625 7.0625 6.50781 3.125 11.375 3.125C16.1875 3.125 20.125 7.0625 20.125 11.875C20.125 16.7422 16.1875 20.625 11.375 20.625C6.50781 20.625 2.625 16.7422 2.625 11.875Z"
      fill={fill}
    />
  </svg>
);
