import React from 'react';

const PlusIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.875 8.5C16.875 9.04688 16.4453 9.4375 15.9375 9.4375H9.6875V15.6875C9.6875 16.2344 9.25781 16.6641 8.75 16.6641C8.20312 16.6641 7.8125 16.2344 7.8125 15.6875V9.4375H1.5625C1.01562 9.4375 0.625 9.04688 0.625 8.53906C0.625 7.99219 1.01562 7.5625 1.5625 7.5625H7.8125V1.3125C7.8125 0.804688 8.20312 0.414062 8.75 0.414062C9.25781 0.414062 9.6875 0.804688 9.6875 1.3125V7.5625H15.9375C16.4453 7.5625 16.875 7.99219 16.875 8.5Z"
        fill="white"
      />
    </svg>
  );
};

export default PlusIcon;
