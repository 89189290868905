import React from 'react';

export const Arrow: React.FC<{
  direction: 'left' | 'right';
  isActive: boolean;
}> = ({ direction, isActive }) => {
  return direction === 'left' ? (
    <svg
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.939433 10.9393C0.353647 11.5251 0.353647 12.4749 0.939433 13.0607L10.4854 22.6066C11.0712 23.1924 12.0209 23.1924 12.6067 22.6066C13.1925 22.0208 13.1925 21.0711 12.6067 20.4853L4.12141 12L12.6067 3.51472C13.1925 2.92893 13.1925 1.97919 12.6067 1.3934C12.0209 0.807611 11.0712 0.807611 10.4854 1.3934L0.939433 10.9393ZM3.41431 10.5H2.00009V13.5H3.41431V10.5Z"
        fill={isActive ? '#2A82B3' : '#9097AF'}
      />
    </svg>
  ) : (
    <svg
      width="13"
      height="24"
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4749 13.0607C13.0607 12.4749 13.0607 11.5251 12.4749 10.9393L2.92893 1.3934C2.34315 0.807611 1.3934 0.807611 0.807611 1.3934C0.221826 1.97919 0.221826 2.92893 0.807611 3.51472L9.29289 12L0.807611 20.4853C0.221826 21.0711 0.221826 22.0208 0.807611 22.6066C1.3934 23.1924 2.34315 23.1924 2.92893 22.6066L12.4749 13.0607ZM10 13.5H11.4142V10.5H10V13.5Z"
        fill={isActive ? '#2A82B3' : '#9097AF'}
      />
    </svg>
  );
};
